<template>
    <div class="content-wrapper workflow" id="workflow">
        <rq-banner
            :message="validationBannerMessage"
            variant="error"
            icon="fas fa-exclamation-circle"
            :visible="showValidationBanner"
            dismissable
        />
        <div class="rq-depts-display">
            <div class="depts-title">MY DEPARTMENTS:</div>
            <div class="depts-list">
                <span v-for="dept in departments" :key="dept.id" class="dept-label">{{dept.text}}</span>
            </div>
        </div>

        <rq-page-section
            title="Filter Tasks"
            header-size="lg"
            class="filter-tasks-section"
            :id-attrs="{ collapseIcon: 'btn_wf_dash_filter_collapse_icon' }"
            v-model:expanded="taskFiltersExpanded"
            collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!taskFiltersExpanded" class="nav rq-filter-display">
                        <li class="nav-item">
                            <div class="filter-name">Regions:</div>
                            <div v-rq-tooltip.hover.top :title="selectedRegionsDisplay" class="filter-value trunc-display">{{selectedRegionsDisplay}}</div>
                        </li>
                        <li class="nav-item">
                            <div class="filter-name">Branches:</div>
                            <div v-rq-tooltip.hover.top :title="selectedBranchesDisplay" class="filter-value trunc-display">{{selectedBranchesDisplay}}</div>
                        </li>
                        <li class="nav-item">
                            <div class="filter-name">Assigned/Status:</div>
                            <div class="filter-value">{{assignedStatusFilterDisplay}}</div>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col-xs-12 col-lg-6">
                    <span class="rq-title col-title">Filter by Region and Branch</span>
                    <div class="form-group">
                        <label for="tb_regions">Region</label>
                        <dx-tag-box
                            id="tb_regions"
                            automation_id="tb_regions"
                            class="form-control"
                            :data-source="regions"
                            display-expr="displayName"
                            value-expr="regionID"
                            :show-selection-controls="true"
                            :show-clear-button="true"
                            :max-displayed-tags="3"
                            :show-drop-down-button="true"
                            apply-value-mode="useButtons"
                            v-model:value="selectedRegions"
                            @valueChanged="onRegionValueChanged"
                        />
                    </div>
                    <div class="form-group">
                        <label for="tb_branches">Branch</label>
                        <dx-tag-box
                            id="tb_branches"
                            automation_id="tb_branches"
                            class="form-control"
                            :data-source="regionBranches"
                            display-expr="name"
                            value-expr="id"
                            :show-selection-controls="true"
                            :show-clear-button="true"
                            :max-displayed-tags="3"
                            :show-drop-down-button="true"
                            :disabled="branchesDisabled"
                            apply-value-mode="useButtons"
                            v-model:value="selectedBranches"
                            @valueChanged="onBranchValueChanged"
                        />
                    </div>
                </div>
                <div class="col-xs-12 col-lg-6 col-xl-4 m-xl-auto">
                    <span class="rq-title col-title">Filter by Assigned Tasks</span>
                    <assignment-totals-grid
                        ref="totalsGrid"
                        v-model="assignmentFilterData"
                        @change="refresh(false)"
                    />
                </div>
            </div>
        </rq-page-section>

        <rqdx-action-data-grid
            ref="actionGridComponent"
            title="Tasks"
            class="task-grid"
            automation_id="dg_tasks"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            search-mode="field"
            :check-action-permission="executionService.canExecuteAction"
            export-file-name="RQO-WorkflowDashboard"
            search-input-id="txt_wf_grid_search"
            @action="onGridAction"
            @rowDoubleClick="onGridRowDoubleClick"
            @clear-filters="onClearFilters"
            rq-grouping
            rq-filters
            :readOnly="readOnly"
            integrated-search
        />

        <notes-popover
                container="#workflow"
                :popover="notesPopover"
                v-model:visible="notesPopover.visible"
            />
    </div>
</template>

<script>
    import DxTagBox from "devextreme-vue/tag-box";
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import AssignmentTotalsGrid from '../components/AssignmentTotalsGrid';
    import { WORKFLOW_ACTIONS as STORE_ACTIONS } from "@/store/actions";
    import WorkflowMixin from "@workflow/workflow-mixin";
    import { TaskGridActions, AssignmentFilterModel } from "../models";
    import { WorkflowTaskStatus } from "@/shared/models/enums";
    import NotesPopover from "@/shared/components/rq/NotesPopover";
    import { DateTime } from "luxon";
    import { DateTimeHelper } from '@/shared/utilities';

    const ASSIGN_ROUTE_PARAMS = {
        AssignedToMe: 1,
        AssignedToMyDept: 2
    };

    const DefaultVisibleColumns = ["taskStatus", "fileNumber", "taskName", "dueDate", "departmentID", "user", "buyer", "address"];
    const FilterableColumns = [
        "taskStatus", "taskName", "dueDate", "departmentID", "user", "buyer", "seller", "address", "notes", "fileNumber",
        "startDate", "outsideParty", "autoEmail", "publish", "closeDate", "city", "state", "zip", "regionID", "branchID",
        "county", "lender", "loanAmount", "loanNumber", "referral", "openDate", "commitmentEffectiveDate", "loanPolicyDate", "policyDate",
        "customer", "closingAgent", "escrowUnit", "escrowOfficer", "escrowProcessor", "orderCategoryID", "salesPrice", "salesRepresentative",
        "orderServiceCode", "statusName", "titleUnit", "titleOfficer", "titleProcessor", "underwriter", "sourceOfBusinessID", "titleCompanyCompanyName",
        "isMe", "isMyDepartment", "isOutsideParty", "isOverdue", "isDueToday", "isDueWithin7", "isDueWithin30", "isOpen", "isComplete"
    ];
    const SortableColumns = [
        "taskStatus", "taskName", "dueDate", "departmentID", "user", "buyer", "seller", "address", "notes", "fileNumber",
        "startDate", "outsideParty", "autoEmail", "publish", "closeDate", "regionID", "branchID", "city", "state", "zip",
        "county", "lender", "loanAmount", "loanNumber", "referral", "openDate", "commitmentEffectiveDate", "loanPolicyDate", "policyDate",
        "customer", "closingAgent", "escrowUnit", "escrowOfficer", "escrowProcessor", "orderCategoryID", "orderServiceCode", "salesPrice", "salesRepresentative",
        "statusName", "titleUnit", "titleOfficer", "titleProcessor", "underwriter", "sourceOfBusinessID", "titleCompanyCompanyName"
    ];
    const GroupableColumns = [
        "taskStatus", "fileNumber", "dueDate", "departmentID", "user", "buyer", "seller", "address",
        "startDate", "outsideParty", "autoEmail", "publish", "closeDate", "regionID", "branchID", "city", "state", "zip",
        "county", "lender", "loanAmount", "referral", "openDate", "commitmentEffectiveDate", "loanPolicyDate", "policyDate",
        "customer", "closingAgent", "escrowUnit", "escrowOfficer", "escrowProcessor", "orderCategoryID", "orderServiceCode", "salesPrice", "salesRepresentative",
        "statusName", "titleUnit", "titleOfficer", "titleProcessor", "underwriter", "sourceOfBusinessID", "titleCompanyCompanyName"
    ];

    export default {
        name: 'WorkflowDashboard',
        mixins: [WorkflowMixin],
        components: {
            DxTagBox,
            AssignmentTotalsGrid,
            NotesPopover
        },
        data: function() {
            return {
                departments: [],
                assignmentFilterData: new AssignmentFilterModel(),
                isAssignmentsCollapsed: true,
                isFilterCollapsed: true,
                taskGridFilter: [],
                searchTerm: "",
                showValidationBanner: false,
                validationBannerMessage: "",
                regionBranches: [],
                selectedRegions: [],
                selectedBranches: [],
                ignoreBranchChange: false,
                taskFiltersExpanded: false,
                notesPopover: {
                    visible: false,
                    target: null,
                    notes: null,
                    description: null
                },
            }
        },

        computed: {
            ...mapState({
                allBranches: state => _.get(state, "system.lookups.branches", []),
                user: state => state.authentication.session.user,
                readOnly: state => _.parseBool(state.isPageReadOnly),
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "executionService",
                "currentSession"
            ]),
            currentUserId() { return this.currentSession.userId; },
            hasTaskGridFilters () { return _.isEmpty(this.taskGridFilter); },
            routeAssignment() { return _.get(this, "$route.query.assignedTo", ""); },
            routeAssignmentStatus() { return _.get(this, "$route.query.assignedToStatus", ""); },
            gridComponent() { return _.get(this, "$refs.actionGridComponent", null); },
            gridInstance() { return _.get(this, "$refs.actionGridComponent.gridInstance", null); },
            regions() { return this.lookupHelpers.getRegions(); },
            branchesDisabled() { return _.isEmpty(this.regionBranches); },
            selectedRegionsDisplay() {
                if(_.isEmpty(this.selectedRegions)) return "All";
                return _.join(_.map(this.selectedRegions, regionID => {
                    let region = _.find(this.regions, { regionID });
                    return region && region.description;
                }), ", ");
            },
            selectedBranchesDisplay() {
                if(_.isEmpty(this.selectedBranches)) return "All";
                return _.join(_.map(this.selectedBranches, id => {
                    let branch = _.find(this.regionBranches, { id });
                    return branch && branch.name;
                }), ", ");
            },
            assignedStatusFilterDisplay() {
                let assigneePart = "";
                switch(this.assignmentFilterData.assignee) {
                    case 1: assigneePart = "Tasks Assigned to Me"; break;
                    case 2: assigneePart = "Tasks Assigned to My Dept"; break;
                    case 3: assigneePart = "Other Tasks"; break;
                }
                if(this.assignmentFilterData.status === 0) {
                    return `All ${assigneePart}`;
                }
                if(this.assignmentFilterData.status === WorkflowTaskStatus.Overdue) {
                    return `Overdue ${assigneePart}`;
                }
                return `${assigneePart} ${WorkflowTaskStatus.displayValue(this.assignmentFilterData.status)}`;
            }
        },

        watch: {
            routeAssignment: {
                handler (newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.assignmentFilterData.assignee = ASSIGN_ROUTE_PARAMS[newValue] || 0;
                },
                immediate: true
            },
            routeAssignmentStatus: {
                handler (newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.assignmentFilterData.status = parseInt(newValue) || 0;
                },
                immediate: true
            },
            searchTerm(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.searchByText(newValue);
            }
        },

        created() {
            this.$store.dispatch(STORE_ACTIONS.UPDATE_DASHBOARD_VIEW_DATE);
            this.initGrid();
        },

        methods: {

            initGrid() {
                const self = this;
                self.setSelectionActions();
                let columns = [
                    {
                        dataField: "taskStatus",
                        caption: "Status",
                        width: 80,
                        cellTemplate: DxGridUtils.statusCellTemplate,
                        groupCellTemplate(element, options) {
                            element.text(WorkflowTaskStatus.displayValue(_.parseNumber(options.value)));
                        },
                        lookup: {
                            displayExpr: "name",
                            valueExpr: "id",
                            dataSource: WorkflowTaskStatus.filterLookupItems
                        }
                    },
                    { dataField: "fileNumber", caption: "File #" },
                    {
                            dataField: "taskName",
                            dataType: "string",
                            caption: "Task Name",
                            cellTemplate: DxGridUtils.orderTasksNotesCellTemplate({
                                idAppend: "workflow-dashboard-popup-info-",
                                handlers:{
                                    mouseover(cellElement, cellInfo, e) {
                                        self.updateNotesPopover(cellInfo.data.taskNote, cellInfo.data.taskName, e.target);
                                    },
                                    mouseout(cellElement, cellInfo, e) {
                                        self.updateNotesPopover();
                                    },
                                    click(cellElement, cellInfo, e) {
                                        e.stopPropagation();
                                    },
                                }
                            })
                        },
                    {
                        dataField: 'dueDate',
                        dataType: "datetime",
                        minWidth: 100,
                        width: 145
                    },
                    {
                        dataField: "departmentID",
                        caption: "Department",
                        lookup: {
                            displayExpr: "text",
                            valueExpr: "id",
                            dataSource: {
                                load: self.fetchDepartments
                            }
                        },
                        rqFilter:{
                            filterType: "tags"
                        },
                        calculateSortValue: "department"
                    },
                    { dataField: "user", caption: "Assigned To" },
                    { dataField: "buyer" },
                    { dataField: "address", caption: "Property Address", minWidth: 150 },

                    // optional columns
                    {
                        dataField: 'startDate',
                        dataType: "datetime",
                        minWidth: 100,
                        width: 145
                    },
                    { dataField: "seller" },
                    { dataField: "notApplicable", caption: "N/A", width: 65, cellTemplate: DxGridUtils.boolCellTemplate },
                    { dataField: "otherCompaniesFileNumber", caption: "Other Company's File #" },
                    { dataField: "notes", caption: "Last Note" },
                    { dataField: "outsideParty" },
                    { dataField: "autoEmail", caption: "Auto Email", cellTemplate: DxGridUtils.boolCellTemplate },
                    { dataField: "publish", caption: "Publish", cellTemplate: DxGridUtils.boolCellTemplate },
                    DxGridUtils.dateColumn({
                        dataField: 'closeDate',
                        minWidth: 100,
                    }),
                    DxGridUtils.dateColumn({
                        dataField: 'fundDate',
                        caption: 'Fund/Disb Date',
                        minWidth: 100,
                    }),
                    {
                        caption: "Region",
                        dataField: "regionID",
                        lookup: {
                            valueExpr: "regionID",
                            displayExpr: "displayName",
                            dataSource: self.regions.slice()
                        },
                        calculateSortValue: "region",
                        rqFilter: {
                            disabled: true,
                            disabledTooltip: "Use fields at the top of the page to filter this column."
                        }
                    },
                    {
                        caption: "Branch",
                        dataField: "branchID",
                        lookup: {
                            valueExpr: "id",
                            displayExpr: "name",
                            dataSource: self.allBranches.slice()
                        },
                        calculateSortValue: "branch",
                        rqFilter: {
                            disabled: true,
                            disabledTooltip: "Use fields at the top of the page to filter this column."
                        }
                    },
                    { dataField: "city" },
                    {
                        dataField: "state",
                        lookup: {
                            valueExpr: "id",
                            displayExpr: "id",
                            dataSource: self.lookupHelpers.getStates()
                        }
                    },
                    { dataField: "zip" },
                    { dataField: "county" },
                    { dataField: "lender" },
                    {
                        dataField: "loanAmount",
                        format: { type:"currency", precision: 2 }
                    },
                    { dataField: "loanNumber" },
                    { dataField: "referral" },
                    {
                        dataField: 'openDate',
                        dataType: "datetime",
                        minWidth: 100,
                    },
                    DxGridUtils.dateColumn({
                        dataField: 'commitmentEffectiveDate',
                        minWidth: 100,
                    }),
                    DxGridUtils.dateColumn({
                        dataField: 'loanPolicyDate',
                        minWidth: 100,
                    }),
                    DxGridUtils.dateColumn({
                        dataField: 'policyDate',
                        caption: 'Owner Policy Date',
                        minWidth: 100,
                    }),
                    { dataField: "customer" },
                    { dataField: "closingAgent" },
                    { dataField: "escrowUnit" },
                    { dataField: "escrowOfficer" },
                    { dataField: "escrowProcessor" },
                    {
                        dataField: "orderCategoryID", caption: "Category",
                        lookup: {
                            valueExpr: "id",
                            displayExpr: "name",
                            data: self.lookupHelpers.getLookupItems(self.lookupItems.ORDER_CATEGORIES)
                        },
                       calculateSortValue: "orderCategory",
                       calculateDisplayValue: "orderCategory"
                    },
                    {
                        dataField: "salesPrice",
                        format: { type:"currency", precision: 2 }
                    },
                    { dataField: "salesRepresentative", caption: "Sales Rep" },
                    {
                        caption: "Service",
                        dataField: "orderServiceCode",
                        lookup: {
                            valueExpr: "id",
                            displayExpr: "name",
                            dataSource: self.lookupHelpers.getOrderServices()
                        },
                        calculateSortValue: "orderService"
                    },
                    { dataField: "statusName", caption: "Order Status" },
                    { dataField: "titleUnit" },
                    { dataField: "titleOfficer" },
                    { dataField: "titleProcessor" },
                    { dataField: "underwriter" },
                    {
                        dataField: "sourceOfBusinessID",
                        caption: "Source of Business",
                        lookup: {
                            valueExpr: "id",
                            displayExpr: "name",
                            dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.SOURCE_OF_BUSINESSES)
                        },
                        calculateSortValue: "sourceOfBusiness"
                    },
                    { dataField: "titleCompanyCompanyName", caption: "Title Company" },
                    { dataField: "isMe", showInColumnChooser: false },
                    { dataField: "isMyDepartment", showInColumnChooser: false },
                    { dataField: "isOutsideParty", showInColumnChooser: false },
                    { dataField: "isOverdue", showInColumnChooser: false },
                    { dataField: "isDueToday", showInColumnChooser: false },
                    { dataField: "isDueWithin7", showInColumnChooser: false },
                    { dataField: "isDueWithin30", showInColumnChooser: false },
                    { dataField: "isOpen", showInColumnChooser: false },
                    { dataField: "isComplete", showInColumnChooser: false },
                ];
                _.forEach(columns, c => {
                    c.visible = _.includes(DefaultVisibleColumns, c.dataField);
                    c.allowFiltering = _.includes(FilterableColumns, c.dataField);
                    c.allowSorting = _.includes(SortableColumns, c.dataField);
                    c.allowGrouping = _.includes(GroupableColumns, c.dataField);
                });

                self.gridConfig = {
                    columns,
                    keyExpr: null,
                    columnChooser: { height: 350 },
                    paging: { enabled: true, pageIndex: 0, pageSize: 50 },
                    grouping: { autoExpandAll: false }
                };
                self.gridDataSource = {
                    key: "orderWorkflowTaskID",
                    load: self.fetchTasks
                };
            },

            setSelectionActions() {
                const self = this;
                this.selectionActions = [
                    {
                        id: "drp_btn_update_tasks",
                        name: "update-tasks",
                        text: "Update",
                        children: [
                            TaskGridActions.UPDATE_DUEDATE,
                            TaskGridActions.MARK_COMPLETE,
                            TaskGridActions.UPDATE_STARTDATE,
                            TaskGridActions.MARK_NA
                        ]
                    },
                    {
                        id: "drp_btn_manage_tasks",
                        name: "manage-tasks",
                        text: "Manage",
                        children: [
                            TaskGridActions.EDIT,
                            TaskGridActions.DELETE,
                            TaskGridActions.EMAIL
                        ]
                    },
                    {
                        id: "drp_btn_assign_tasks",
                        name: "assign-tasks",
                        text: "Assign",
                        children: [
                            TaskGridActions.ASSIGN_ME,
                            TaskGridActions.ASSIGN
                        ]
                    },
                    {
                        ...TaskGridActions.ADD_NOTE,
                    },
                    {
                        ...TaskGridActions.GO_TO_FILE,
                        disabled(args) {
                            let tasks = args.data || [];
                            let commonOrderId = _.reduceMatchingProp(tasks, "ordersID");
                            return _.isNil(commonOrderId);
                        }
                    }
                ];
            },

            fetchDepartments() {
                let self = this;
                if(!_.isEmpty(self.departments))
                    return Promise.resolve(self.departments.slice());
                let promise = self.$api.OrderWorkflowApi.getDepartmentsByUserId(self.currentUserId)
                    .then(result => {
                        self.departments = _.uniqBy(_.map(result, item => ({ id: item.workflowDepartmentID, text: item.departmentName })), 'id');
                        return self.departments.slice();
                    })
                    .catch(errorInfo => {
                        self.$toast.error({ message: 'Failed to get departments' });
                        console.error(errorInfo);
                        return errorInfo;
                    });
                self.$rqBusy.wait(promise);
                return promise;
            },

            fetchTasks (loadOptions) {
                const self = this;
                if(self.cancelFetchData(loadOptions)) return;

                if (loadOptions.filter) self.taskGridFilter = loadOptions.filter;

                let hasGrouping = !_.isEmpty(loadOptions.group);
                const parseFilterExpr = filterExpr => {
                    let newExpr = [];
                    if(!_.isArray(filterExpr)) return filterExpr;
                    if(_.isString(filterExpr[0])){
                        if(filterExpr[2] !== "" && filterExpr[2] !== null && filterExpr[2] !== "No Value") return filterExpr;
                        newExpr.push(...filterExpr);
                        newExpr.push(["ISNULL",""]);
                        newExpr[2] = "";
                        return newExpr;
                    }
                    _.forEach(filterExpr, expr => {
                        if(_.isArray(expr)) {
                            newExpr.push(parseFilterExpr(expr));
                        }
                        else {
                            newExpr.push(expr);
                        }
                    });
                    return newExpr;
                };

                const processDueDateFilter = filter => {
                    let newExpr = [];
                    if(!_.isArray(filter)) return filter;
                    _.forEach(filter, expr => {
                        if(_.isArray(expr)) {

                            // if(filter[1] && filter[1] === 'and'){
                            //
                            // }
                            if((_.isArray(filter[0]) && _.isArray(filter[0])) && (filter[0][0] === filter[2][0])){
                                newExpr.push(expr);
                            }
                            else {
                                newExpr.push(processDueDateFilter(expr));
                            }
                        }
                        else {
                            if((expr === 'dueDate' || expr === 'startDate') && filter[1] === "=" && !_.isEmpty(filter[2])) {
                                let dateValue = filter[2];
                                let dateTime = DateTime.now();
                                try {
                                    dateTime = DateTimeHelper.getDateFromString(dateValue);
                                } catch (error) { /* empty */ }

                                let dateTimePlus1Second = dateTime
                                                        .plus({ seconds: 1 })
                                                        .toFormat("MM/dd/yyyy HH:mm:ss");
                                newExpr.push(...[
                                    [ expr, ">=", dateValue ],
                                    "and",
                                    [ expr, "<", dateTimePlus1Second ]
                                ]);
                                return false;
                            }
                            else {
                                newExpr.push(expr);
                            }
                        }
                    });
                    return newExpr;
                };

                let filter = processDueDateFilter(parseFilterExpr(loadOptions.filter));

                let searchRequest = {
                    status: self.assignmentFilterData.status,
                    assignee: self.assignmentFilterData.assignee,
                    loadOptions: {
                        filter: filter,
                        skip: loadOptions.skip || 0,
                        take: loadOptions.take || 50,
                        sort: loadOptions.sort || null,
                        group: loadOptions.group || null
                    },
                    searchTerm: self.searchTerm,
                    pagingEnabled: false
                };

                let apiPromise = self.$api.OrderWorkflowApi.getDashboardTasks(searchRequest);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        let result = { totalCount: response.totalRecords };
                        let pagerTotal = response.totalRecords;
                        if(hasGrouping) {
                            pagerTotal = response.groupCount;
                            result.data = _.map(response.results, item => ({
                                items: null,
                                key: item.key === "01/01/1900 00:00:00" ? "No Value" : item.key,
                                count: item.count,
                                summary: [item.count]
                            }));
                            result.groupCount = response.groupCount;
                        }
                        else {
                            result.data = response.results;
                        }
                        //self.gridInstance.option("pager.visible", pagerTotal > 50);
                        return result;
                    })
                    .catch(errorInfo => {
                        self.$toast.error({ message: 'Failed to get Dashboard Tasks' });
                        console.error(errorInfo);
                        return errorInfo;
                    });
            },

            cancelFetchData(loadOptions) {
                return _.isEmpty(loadOptions)
                    || _.get(loadOptions, "filter[0]", null) === "orderWorkflowTaskID"
                    || _.get(loadOptions, "filter[0][0]", null) === "orderWorkflowTaskID";
            },

            clearFilter () {
                this.taskGridFilter = [];
                this.gridInstance.clearFilter();
            },

            //EVENT Handlers

            onGridAction(e) {
                this.handleAction(e);
            },

            onGridRowDoubleClick(e) {
                if (this.readOnly) {return;}
                this.handleEditTask(e.data);
            },

            async onRegionValueChanged (e) {
                this.ignoreBranchChange = true;
                let regionIds = e.value || [];
                this.regionBranches = [];

                if (regionIds.length > 0) {
                    this.regionBranches = _.uniq(_.flatMap(regionIds, rid => this.lookupHelpers.getBranches(rid)));
                    this.selectedBranches = _.filter(this.selectedBranches, bId => _.some(this.regionBranches, rb => rb.branchID === bId));
                }
                else {
                    this.selectedBranches = [];
                }

                await this.updateFilters([
                    { dataField: "regionID", values: this.selectedRegions },
                    { dataField: "branchID", values: this.selectedBranches }
                ]);
                this.ignoreBranchChange = false;
            },

            onBranchValueChanged (e) {
                if(this.ignoreBranchChange) return;
                this.updateFilters([
                    {dataField: "branchID", values: this.selectedBranches }
                ]);
            },

            onClearFilters() {
                this.selectedRegions = [];
                this.selectedBranches = [];
            },

            searchByText: _.debounce(function(val) {
                // filters will need to be configured differently to utilized search through the grid (to highlight matches)
                // this.gridInstance.searchByText(val);
                this.gridInstance.refresh();
            }, 500),

            handleAction (e) {
                const self = this;
                let action = e.action;
                let data = e.data;

                self.messageBanner();
                if(!action || !data) return;

                let message = `No handler for action - ${e.action.key}`;
                switch (action.key) {
                    case TaskGridActions.EDIT.key:
                        self.handleEditTask(data, _.getBool(data, "isOrderLocked"));
                        break;
                    case TaskGridActions.DELETE.key:
                        self.handleDeleteTasks(data);
                        break;
                    case TaskGridActions.EMAIL.key:
                        self.handleEmailTask(data, false);
                        break;
                    case TaskGridActions.ASSIGN_ME.key:
                        self.handleAssignTasksToMe(data);
                        break;
                    case TaskGridActions.ASSIGN.key:
                        self.handleAssignTasks(data);
                        break;
                    case TaskGridActions.MARK_COMPLETE.key:
                        self.handleMarkTasksComplete(data);
                        break;
                    case TaskGridActions.MARK_NA.key:
                        self.handleToggleTasksNonApplicable(data);
                        break;
                    case TaskGridActions.ADD_NOTE.key:
                        self.handleEditNote(data);
                        break;
                    case TaskGridActions.GO_TO_FILE.key:
                        self.navigateToTaskOrder(data);
                        break;
                    case TaskGridActions.UPDATE_DUEDATE.key:
                        self.handleUpdateDueDate(data);
                        break;
                    case TaskGridActions.UPDATE_STARTDATE.key:
                        self.handleUpdateStartDate(data);
                        break;
                    default:
                        self.$toast.error(message);
                        console.error(message);
                        break;
                }
            },

            navigateToTaskOrder(data) {
                let orderId = _.isArray(data)
                    ? _.reduceMatchingProp(data, "ordersID")
                    : _.parseNumber(data.ordersID, 0);

                if(orderId === 0) {
                    this.$toast.error("Invalid file ID on selected tasks.");
                    return;
                }
                this.$rq.goToFile({ name: "ow:order-workflow", noAccessDefault: "oe:main", orderId });
            },

            collapseSections() {
                this.isAssignmentsCollapsed = true;
                this.isFilterCollapsed = true;
            },

            clearSelection() {
                this.gridInstance?.option("focusedRowIndex", -1);
                this.gridInstance?.clearSelection();
            },

            refresh (reloadTotals=true) {
                this.clearSelection();
                this.gridInstance?.refresh();
                if (!reloadTotals) return;
                this.$refs.totalsGrid.reload();
            },

            onAssignmentFilterChange(e)
            {
                let allFilterColumns = [
                    "isMe",
                    "isMyDepartment",
                    "isOutsideParty",
                    "isDueToday",
                    "isDueWithin7",
                    "isDueWithin30",
                    "isOverdue"
                ];

                let assignee = "None"
                switch(e?.filters?.assignee) {
                    case 1: assignee = "isMe"; break;
                    case 2: assignee = "isMyDepartment"; break;
                    case 3: assignee = "isOutsideParty"; break;
                    default: assignee = "All"; break;
                }

                let status = "None"
                switch(e?.filters?.status) {
                    case 1: status = "isDueToday"; break;
                    case 2: status = "isDueWithin7"; break;
                    case 3: status = "isDueWithin30"; break;
                    case 5: status = "isOverdue"; break;
                    default: status = "All"; break;
                }

                this.clearSelection();
                this.updateFilter([
                    { dataField: assignee, values: assignee === "All" ? null : true},
                    { dataField: assignee, values: status === "All" ? null : true},
                ], allFilterColumns)
            },

            async updateFilters(filtersToUpdate, filtersToRemove=[]){
                let newFilters = _.filter(filtersToUpdate, f => _.isArray(f.values) ? !_.isEmpty(f.values) : !_.isNil(f.values));
                let removeFilters = _.isEmpty(filtersToRemove) ? _.map(filtersToUpdate, "dataField") : filtersToRemove;

                let noNewFilters = _.isEmpty(newFilters);

                await this.gridComponent?.removeColumnFilters(removeFilters, noNewFilters);

                if(noNewFilters) return;

                await this.gridComponent?.updateFilters(newFilters);

            },

            updateNotesPopover(notes=null, description=null, target=null) {
                const self = this;
                let newID = _.get(target, "id") || null;
                let lastID = _.get(self.notesPopover, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let notesPopover = _.clone(self.notesPopover);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        notesPopover.visible = false;
                    }
                    notesPopover.target = newID;
                    notesPopover.notes = notes;
                    notesPopover.description = description;
                    notesPopover.visible = true;
                } else {
                    notesPopover.visible = !notesPopover.visible;
                }
                self.$nextTick(() => {
                    self.notesPopover = notesPopover;
                });
            },
        }
    }
</script>
